<template>
  <div v-loading="loading">
    <en-table-layout :tableData="pageData.data" @selection-change="orderListSelectionChange" :row-key="getRowKeys"
      :span-method="spanMethod" ref="enTableLayout1">
      <template slot="header">
        <el-tabs v-model="activeName" type="card" @tab-click="setType">
          <el-tab-pane label="销售订单核销" name="1"></el-tab-pane>
          <el-tab-pane label="销售退单核销" name="2"></el-tab-pane>
          <el-tab-pane label="来往对账核销" name="3"></el-tab-pane>
        </el-tabs>
      </template>
      <template v-if="activeName == 1" slot="toolbar">
        <el-form-item label="付款状态" class="col-auto">
          <el-select style="width: 140px" size="medium" v-model="advancedForm.pay_status" placeholder="请选择" clearable>
            <el-option v-for="item in payType" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="单据状态" class="col-auto">
          <el-select style="width: 120px" size="medium" v-model="advancedForm.trade_status" placeholder="请选择" clearable>
            <el-option v-for="(item, index) in tradeType" :key="index" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="卡号区间" style="position: relative">
          <el-input style="width: 110px" size="small" v-model="advancedForm.cardCodeStart" @input="setIntervalCheck" />
          <span style="margin: 0 5px">-</span>
          <el-input style="width: 110px" size="small" v-model="advancedForm.cardCodeEnd" @input="setIntervalCheck" />
          <div v-if="IntervalCheck === '2'" class="cardInterval">
            当前卡号区间格式不一致
          </div>
          <div v-else-if="IntervalCheck === '1'" class="cardInterval">
            结束框卡号数字部分不能小于起始输入框卡号数字部分
          </div>
          <!-- <div v-else-if="IntervalCheck==='3'" class="cardInterval">卡号区间搜索不能和其他筛选条件同时使用</div> -->
          <div v-else-if="IntervalCheck === '5'" class="cardInterval">
            请输入正确卡号区间进行搜索
          </div>
          <div v-else-if="IntervalCheck === '4'" class="cardInterval">
            当前卡号区间格式不正确，请重新填写卡号区间
          </div>
          <div v-else-if="IntervalCheck === '6'" class="cardInterval">
            结束框卡号数字部分不能小于起始输入框卡号数字部分
          </div>
        </el-form-item>
        <el-form-item label="单据生成日期">
          <el-date-picker style="width: 250px" v-model="advancedForm.order_time_range" type="daterange" align="center"
            size="medium" :editable="false" unlink-panels range-separator="-" start-placeholder="开始日期"
            end-placeholder="结束日期"></el-date-picker>
        </el-form-item>
        <div v-if="IntervalCheck !== '' && IntervalCheck !== '3'" style="width: 100%; height: 10px"></div>
      </template>
      <template v-if="activeName == 1" slot="toolbar_btn">
        <el-form-item label="应用类型" class="col-auto">
          <el-select style="width: 140px" v-model="advancedForm.app_type_shop_type_flag" size="medium" clearable>
            <el-option v-for="(item, index) in filterdouble" :key="index" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input size="medium" v-model="advancedForm.keywords" @input="
            advancedForm.keywords = advancedForm.keywords.replace(
              /\s+/g,
              ''
            )
            " placeholder="请输入关键字进行搜索" clearable>
            <el-select slot="prepend" size="medium" v-model="advancedForm.type" placeholder="请选择类型" style="width: 130px">
              <el-option label="销售单据编号" value="sell_refund_record_sn"></el-option>
              <el-option label="客户名称" value="client_name"></el-option>
              <el-option label="联系人名称" value="link_name"></el-option>
              <el-option label="销售人员名称" value="sell_name"></el-option>
              <el-option label="商城名称" value="shop_name"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-button :disabled="IntervalCheck === '1' || IntervalCheck === '2'" @click="advancedSearchEvent" type="primary"
          size="small">搜索</el-button>
        <el-button @click="submitImport(0)" :loading="importLoading" size="small" type="primary">导出全部</el-button>
        <el-button @click="submitImport(1)" :loading="importLoading1" size="small" type="primary">导出勾选项</el-button>
      </template>
      <template v-if="activeName == 1" slot="table-columns">
        <el-table-column type="selection" :reserve-selection="true" fixed />
        <el-table-column prop="sell_refund_record_sn" align="center" label="销售单据编号" width="160" fixed="left" />
        <el-table-column prop="shop_name" label="所属商城" align="center" width="160" show-overflow-tooltip />
        <el-table-column align="center" prop="card_num" label="需开卡总数" width="100" />
        <el-table-column align="center" prop="open_card_num" label="已开卡数量" width="100" />
        <el-table-column align="center" prop="total_payable_price" label="应收金额" width="80" />
        <el-table-column align="center" prop="curr_payable_price" label="已收金额" width="80" />
        <el-table-column prop="client_name" align="center" label="客户名称" width="150" show-overflow-tooltip />
        <el-table-column align="center" prop="app_type_shop_type_flag" label="应用类型" width="136">
          <template slot-scope="{ row }">
            {{ getAppTypeName(row.app_type_shop_type_flag) }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="link_name" label="联系人名称" width="100" show-overflow-tooltip />
        <el-table-column align="center" prop="sell_name" label="销售人员" width="120" show-overflow-tooltip />
        <el-table-column align="center" prop="create_time" label="单据生成日期" width="150">
          <template slot-scope="{ row }">{{
            row.create_time | unixToDate
          }}</template>
        </el-table-column>
        <el-table-column align="center" prop="pay_status" label="付款状态" width="80">
          <template slot-scope="{ row }">
            {{ getPayTypeName(row.pay_status) }}
            <p v-if="row.total_payable_price < row.curr_payable_price">
              (超付)
            </p>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="trade_status" label="单据状态" width="80">
          <template slot-scope="{ row }">
            {{ getTradeTypeName(row.trade_status) }}
          </template>
        </el-table-column>
        <el-table-column prop="payable_remark" label="收款备注" width="150" show-overflow-tooltip />
        <el-table-column align="center" label="操作" width="300" fixed="right">
          <template slot-scope="{ row }">
            <el-button type="primary" size="mini" @click="getInfoUnderWrite(row)">查看</el-button>
            <el-button type="primary" size="mini" @click="getRemarkUnderWrite(row)">核销记录</el-button>
            <el-button v-if="row.trade_status == 0 || row.trade_status == 1" type="primary" size="mini"
              @click="cancelAterVerification(row)">核销</el-button>
            <el-button v-if="!(row.trade_status === 3 || row.open_card_status === 1)" type="primary" size="mini"
              @click="getCloseCardsTypeChange(row)">开卡</el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination v-if="activeName == 1" slot="pagination" @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange" :current-page="pageData.page_no" :page-size="pageData.page_size"
        :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background :total="pageData.data_total"></el-pagination>


      <template v-if="activeName == 2" slot="toolbar">
        <el-form-item label="退款状态" class="col-auto">
          <el-select style="width: 140px" size="medium" v-model="advancedForm.refund_status" placeholder="请选择" clearable>
            <el-option v-for="item in refundType" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="单据状态" class="col-auto">
          <el-select style="width: 120px" size="medium" v-model="advancedForm.status" placeholder="请选择" clearable>
            <el-option v-for="(item, index) in tradeType" :key="index" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="卡号区间" style="position: relative">
          <el-input style="width: 110px" size="small" v-model="advancedForm.cardCodeStart" @input="setIntervalCheck" />
          <span style="margin: 0 5px">-</span>
          <el-input style="width: 110px" size="small" v-model="advancedForm.cardCodeEnd" @input="setIntervalCheck" />
          <div v-if="IntervalCheck === '2'" class="cardInterval">
            当前卡号区间格式不一致
          </div>
          <div v-else-if="IntervalCheck === '1'" class="cardInterval">
            结束框卡号数字部分不能小于起始输入框卡号数字部分
          </div>
          <!-- <div v-else-if="IntervalCheck==='3'" class="cardInterval">卡号区间搜索不能和其他筛选条件同时使用</div> -->
          <div v-else-if="IntervalCheck === '5'" class="cardInterval">
            请输入正确卡号区间进行搜索
          </div>
          <div v-else-if="IntervalCheck === '4'" class="cardInterval">
            当前卡号区间格式不正确，请重新填写卡号区间
          </div>
          <div v-else-if="IntervalCheck === '6'" class="cardInterval">
            结束框卡号数字部分不能小于起始输入框卡号数字部分
          </div>
        </el-form-item>
        <el-form-item label="单据生成日期">
          <el-date-picker style="width: 250px" v-model="advancedForm.order_time_range" type="daterange" align="center"
            size="medium" :editable="false" unlink-panels range-separator="-" start-placeholder="开始日期"
            end-placeholder="结束日期"></el-date-picker>
        </el-form-item>
        <div v-if="IntervalCheck !== '' && IntervalCheck !== '3'" style="width: 100%; height: 10px"></div>
      </template>
      <template v-if="activeName == 2" slot="toolbar_btn">
        <el-form-item label="应用类型" class="col-auto">
          <el-select style="width: 140px" v-model="advancedForm.app_type_shop_type_flag" size="medium" clearable>
            <el-option v-for="(item, index) in cardTypes" :key="index" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input size="medium" v-model="advancedForm.keywords" @input="
            advancedForm.keywords = advancedForm.keywords.replace(
              /\s+/g,
              ''
            )
            " placeholder="请输入关键字进行搜索" clearable>
            <el-select slot="prepend" size="medium" v-model="advancedForm.type" placeholder="请选择类型" style="width: 130px">
              <el-option label="销售退单编号" value="sn"></el-option>
              <el-option label="关联销售单号" value="sell_refund_record_sn"></el-option>
              <el-option label="客户名称" value="client_name"></el-option>
              <el-option label="联系人名称" value="link_name"></el-option>
              <el-option label="销售人员名称" value="sell_name"></el-option>
              <el-option label="商城名称" value="shop_name"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-button @click="advancedSearchEvent" type="primary" size="small">搜索</el-button>
        <el-button @click="submitImport(0)" :loading="importLoading" size="small" type="primary">导出全部</el-button>
        <el-button @click="submitImport(1)" :loading="importLoading1" size="small" type="primary">导出勾选项</el-button>
      </template>
      <template v-if="activeName == 2" slot="table-columns">
        <el-table-column align="center" type="selection" :reserve-selection="true" fixed />
        <el-table-column prop="sn" align="center" label="销售退单编号" width="160" fixed="left" />
        <el-table-column align="center" prop="sell_refund_record_sn" label="关联销售单号" width="160" fixed="left" />
        <el-table-column align="center" prop="shop_name" label="所属商城" width="160" show-overflow-tooltip />
        <el-table-column prop="refund_num" align="center" label="需退卡总数" width="120">
          <template slot-scope="{ row }">
            <p>{{ row.refund_num || row.card_num || 0 }}</p>
          </template>
        </el-table-column>
        <el-table-column align="center" label="已退卡数量" width="100">
          <template slot-scope="{ row }">
            {{ row.refund_card_num || row.curr_refund_num || 0 }}
          </template></el-table-column>
        <el-table-column align="center" prop="after_discount_price" label="应退金额" width="80" />
        <el-table-column align="center" prop="refunded_price" label="已退金额" width="80" />
        <el-table-column align="center" prop="client_name" label="客户名称" width="150" show-overflow-tooltip />
        <el-table-column align="center" prop="app_type_shop_type_flag" label="应用类型" width="136">
          <template slot-scope="{ row }">
            {{ getAppTypeName(row.app_type_shop_type_flag) }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="link_name" label="联系人名称" width="120" show-overflow-tooltip />
        <el-table-column align="center" prop="sell_name" label="销售人员" width="120" show-overflow-tooltip />
        <el-table-column align="center" prop="create_time" label="单据生成日期" width="150">
          <template slot-scope="{ row }">{{
            row.create_time | unixToDate
          }}</template>
        </el-table-column>
        <el-table-column align="center" prop="refund_status" label="退款状态" width="80">
          <template slot-scope="{ row }">
            {{ getRefundTypeName(row.refund_status) }}
            <p v-if="row.after_discount_price < row.refunded_price">
              (超退)
            </p>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="status" label="单据状态" width="80">
          <template slot-scope="{ row }">
            {{ getTradeTypeName(row.status) }}
          </template>
        </el-table-column>
        <el-table-column prop="refund_remark" label="退款备注" width="150" show-overflow-tooltip />
        <el-table-column align="center" label="操作" width="300" fixed="right">
          <template slot-scope="{ row }">
            <el-button type="primary" size="mini" @click="getInfoUnderWrite(row)">查看</el-button>
            <el-button type="primary" size="mini" @click="getRemarkUnderWrite(row)">核销记录</el-button>
            <el-button v-if="row.status == 0 || row.status == 1" type="primary" size="mini"
              @click="cancelAterVerification(row)">核销</el-button>
            <el-button v-if="!(row.status === 3 || row.close_card_status === 1)" type="primary" size="mini"
              @click="getCloseCardsTypeChange(row)">退卡</el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination v-if="activeName == 2" slot="pagination" @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange" :current-page="pageData.page_no" :page-size="pageData.page_size"
        :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background :total="pageData.data_total"></el-pagination>





      <template v-if="activeName == 3" slot="toolbar">
        <el-form-item label="单据生成日期">
          <el-date-picker style="width: 250px" v-model="advancedForm.order_time_range" type="daterange" align="center"
            size="medium" :editable="false" unlink-panels range-separator="-" start-placeholder="开始日期"
            end-placeholder="结束日期"></el-date-picker>
        </el-form-item>
        <el-form-item label="卡号区间" style="position: relative">
          <el-input style="width: 110px" size="small" v-model="advancedForm.cardCodeStart" @input="setIntervalCheck" />
          <span style="margin: 0 5px">-</span>
          <el-input style="width: 110px" size="small" v-model="advancedForm.cardCodeEnd" @input="setIntervalCheck" />
          <div v-if="IntervalCheck === '2'" class="cardInterval">
            当前卡号区间格式不一致
          </div>
          <div v-else-if="IntervalCheck === '1'" class="cardInterval">
            结束框卡号数字部分不能小于起始输入框卡号数字部分
          </div>
          <!-- <div v-else-if="IntervalCheck==='3'" class="cardInterval">卡号区间搜索不能和其他筛选条件同时使用</div> -->
          <div v-else-if="IntervalCheck === '5'" class="cardInterval">
            请输入正确卡号区间进行搜索
          </div>
          <div v-else-if="IntervalCheck === '4'" class="cardInterval">
            当前卡号区间格式不正确，请重新填写卡号区间
          </div>
          <div v-else-if="IntervalCheck === '6'" class="cardInterval">
            结束框卡号数字部分不能小于起始输入框卡号数字部分
          </div>
        </el-form-item>
        <el-form-item label="应用类型" class="col-auto">
          <el-select style="width: 140px" v-model="advancedForm.app_type_shop_type_flag" size="medium" clearable>
            <el-option v-for="(item, index) in cardTypes" :key="index" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <div v-if="IntervalCheck !== '' && IntervalCheck !== '3'" style="width: 100%; height: 10px"></div>
      </template>
      <template v-if="activeName == 3" slot="toolbar_btn">
        <el-form-item>
          <el-input size="medium" v-model="advancedForm.keywords" @input="
            advancedForm.keywords = advancedForm.keywords.replace(
              /\s+/g,
              ''
            )
            " placeholder="请输入关键字进行搜索" clearable>
            <el-select slot="prepend" size="medium" v-model="advancedForm.type" placeholder="请选择类型" style="width: 130px">
              <el-option label="销售单据编号" value="sell_refund_record_sn"></el-option>
              <el-option label="客户名称" value="client_name"></el-option>
              <el-option label="销售人员名称" value="sell_name"></el-option>
              <el-option label="商城名称" value="shop_name"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-button @click="advancedSearchEvent" type="primary" size="small">搜索</el-button>
        <el-button @click="submitImport(0)" :loading="importLoading" size="small" type="primary">导出全部</el-button>
        <el-button @click="submitImport(1)" :loading="importLoading1" size="small" type="primary">导出勾选项</el-button>
      </template>
      <template v-if="activeName == 3" slot="table-columns">
        <el-table-column type="selection" :reserve-selection="true" fixed />
        <el-table-column align="center" prop="sell_refund_record_sn" label="销售单据编号" width="160" fixed="left" />
        <el-table-column align="center" prop="shop_name" label="所属商城" width="160" show-overflow-tooltip />
        <el-table-column align="center" prop="card_num" label="需开卡总数" width="100" />
        <el-table-column align="center" prop="refund_num" label="需退卡总数" width="100" />
        <el-table-column align="center" prop="open_card_num" label="已开卡数量" width="100" />
        <el-table-column align="center" prop="curr_refund_num" label="已退卡数量" width="100" />
        <el-table-column align="center" prop="total_payable_price" label="应收金额" width="80" />
        <el-table-column align="center" prop="curr_payable_price" label="已收金额" width="80" />
        <el-table-column align="center" prop="total_refund_price" label="应退金额" width="80" />
        <el-table-column align="center" prop="curr_refund_price" label="已退金额" width="80" />
        <el-table-column align="center" prop="balance" label="余额" width="80" />
        <el-table-column align="center" prop="client_name" label="客户名称" width="150" show-overflow-tooltip />
        <el-table-column align="center" prop="app_type_shop_type_flag" label="应用类型" width="136">
          <template slot-scope="{ row }">
            {{ getAppTypeName(row.app_type_shop_type_flag) }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="link_name" label="联系人名称" width="100" show-overflow-tooltip />
        <el-table-column align="center" prop="sell_name" label="销售人员" width="120" show-overflow-tooltip />
        <el-table-column align="center" prop="create_time" label="单据生成日期" width="150">
          <template slot-scope="{ row }">{{
            row.create_time | unixToDate
          }}</template>
        </el-table-column>
        <el-table-column prop="remark" label="备注信息" width="150" show-overflow-tooltip />
        <el-table-column label="操作" width="80" fixed="right">
          <template slot-scope="{ row }">
            <el-button type="primary" size="mini" @click="() => {
                  remarkDialog.display();
                  cancelAterVerificationForm = row;
                  remark = row.remark;
                }
                ">备注</el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination v-if="activeName == 3" slot="pagination" @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange" :current-page="pageData.page_no" :page-size="pageData.page_size"
        :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background :total="pageData.data_total"></el-pagination>
    </en-table-layout>



    <x-dialog :proxy="recordDialog">
      <el-table :header-cell-style="{
        textAlign: 'center',
        backgroundColor: 'rgba(230, 236, 247, 1)',
      }" style="line-height: 1" :data="recordTable" border>
        <el-table-column label="编号">
          <template slot-scope="scope">{{ scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column label="核销金额" prop="price"></el-table-column>
        <el-table-column label="核销时间">
          <template slot-scope="{ row }">{{
            row.create_time | unixToDate
          }}</template>
        </el-table-column>
        <el-table-column label="操作人员" prop="user_name"></el-table-column>
        <el-table-column label="收款备注" prop="remark" show-overflow-tooltip></el-table-column>
      </el-table>
    </x-dialog>

    <x-dialog :proxy="operDialog">
      <el-form label-width="120px" :model="cancelAterVerificationForm" :rules="rules" ref="cancelAterVerificationForm"
        class="c-a-form">
        <el-form-item label="客户名称">{{
          cancelAterVerificationForm.client_name
        }}</el-form-item>
        <el-form-item v-if="activeName === '1'" label="应收金额">{{
          cancelAterVerificationForm.total_payable_price
        }}</el-form-item>
        <el-form-item v-if="activeName === '1'" label="已收金额">{{
          cancelAterVerificationForm.curr_payable_price
        }}</el-form-item>
        <el-form-item v-if="activeName === '2'" label="应退金额">{{
          cancelAterVerificationForm.after_discount_price
        }}</el-form-item>
        <el-form-item v-if="activeName === '2'" label="已退金额">{{
          cancelAterVerificationForm.curr_refund_price
        }}</el-form-item>
        <el-form-item label="核销金额" prop="change_price">
          <el-input style="width: 200px" size="medium" :controls="false" v-model="cancelAterVerificationForm.change_price"
            type="text" placeholder="请输入需要核销的金额" @blur="checkPrice" @input="changePrice"></el-input>
        </el-form-item>
        <el-form-item v-if="activeName === '1'" label="收款备注" prop="link_name">
          <el-input style="width: 300px" size="medium" type="textarea" maxlength="100"
            v-model="cancelAterVerificationForm.payable_remark" :rows="3" placeholder="请输入100字以内的备注信息"></el-input>
        </el-form-item>
        <el-form-item v-if="activeName === '2'" label="退款备注" prop="link_name">
          <el-input style="width: 300px" size="medium" type="textarea" maxlength="100"
            v-model="cancelAterVerificationForm.refund_remark" :rows="3" placeholder="请输入100字以内的备注信息"></el-input>
        </el-form-item>
      </el-form>
    </x-dialog>

    <x-dialog :proxy="operCardDialog">
      <el-table :header-cell-style="{
        textAlign: 'center',
        backgroundColor: 'rgba(230, 236, 247, 1)',
      }" style="line-height: 1" :data="cardList" :span-method="cardSpanMethod" border>
        <el-table-column label="所属商城" width="230" prop="shop_name" show-overflow-tooltip></el-table-column>
        <el-table-column label="卡券名称" width="200" show-overflow-tooltip prop="card_name"></el-table-column>
        <el-table-column label="卡号区间" prop="price">
          <template slot-scope="{ row }">
            <span>{{ row.card_code_start + "~" + row.card_code_end }}</span>
          </template>
        </el-table-column>
      </el-table>
    </x-dialog>

    <x-dialog :proxy="remarkDialog">
      <div style="padding: 10px">
        <el-input type="textarea" :autosize="{ minRows: 8, maxRows: 10 }" placeholder="请输入100字以内的备注信息" maxlength="100"
          v-model="remark"></el-input>
      </div>
    </x-dialog>
  </div>
</template>

<script>
import * as API_order from "@/api/order";
import * as API_Login from "@/api/login";
import { handleDownload, downloadExcel } from "@/utils";
import { Foundation } from "@/../ui-utils";
import EnTableLayout from "../../../ui-components/TableLayout/src/main";
import XDialog from "@/components/x-dialog/x-dialog";
import { $xDialog } from "@/components/x-dialog/dialog.proxy";
import mixins from "@/views/tools/sale-card-open/modules/saleListMixins";
import {
  changeDataList,
  createShopCardList,
  spanMethod,
} from "@/views/tools/sale-card-open/utils/span";
import resize from "../layout/mixin/resize";

let that = null;
// 判断核销金额是否超出范围
const validatorAccountNumber = (rule, value, callback) => {
  const thatForm = that.cancelAterVerificationForm;
  if (!value) {
    return callback(new Error("核销金额为必填项"));
  } else {
    if (that.activeName === "1") {
      console.log(value + Number(thatForm.curr_payable_price));
      if (
        Number(value) + Number(thatForm.curr_payable_price) >
        thatForm.total_payable_price
      ) {
        return callback(new Error("当前要核销的金额超出可核销金额范围"));
      } else {
        return callback();
      }
    } else {
      if (
        Number(value) + Number(thatForm.curr_refund_price) >
        thatForm.after_discount_price
      ) {
        return callback(new Error("当前要核销的金额超出可核销金额范围"));
      } else {
        return callback();
      }
    }
  }
};

export default {
  name: "openCardWrite",
  mixins: [mixins],
  components: {
    EnTableLayout,
    XDialog,
  },
  computed: {
    filterdouble () {
      if (!this.double_choice_mall_open) {
        this.cardTypes.splice(2, 1)
      }
      return this.cardTypes;
    }
  },
  data () {
    return {
      double_choice_mall_open: true,//是否开启双选商城
      recordDialog: $xDialog.create({
        title: "核销记录",
        width: "680px",
        disableConfirm: true,
        disableCancel: true,
      }),
      operDialog: $xDialog.create({
        title: "核销操作",
        width: "500px",
        beforeConfirm: () => {
          this.$refs["cancelAterVerificationForm"].validate((valid) => {
            if (valid) {
              let params = {
                client_name: this.cancelAterVerificationForm.client_name,
                change_price: this.cancelAterVerificationForm.change_price,
                ids: this.cancelAterVerificationForm.ids,
                trade_type: this.params.trade_type,
              };
              if (this.params.trade_type === "2") {
                params.sn = this.cancelAterVerificationForm.sn;
                params.remark = this.cancelAterVerificationForm.refund_remark;
              } else {
                params.sell_refund_record_sn =
                  this.cancelAterVerificationForm.sell_refund_record_sn;
                params.remark = this.cancelAterVerificationForm.payable_remark;
              }
              API_order.editCurrPrice(params).then((res) => {
                if (res.code !== 200) return this.$message.error(res.massage);
                this.operDialog.dismiss();
                this.$message.success(res.massage);
                this.GET_OrderList(this.params);
              });
            } else {
              console.log("error submit!!");
            }
          });
          return false;
        },
      }),
      operCardDialog: $xDialog.create({
        width: "800px",
      }),
      remarkDialog: $xDialog.create({
        title: "备注说明",
        width: "500px",
        beforeConfirm: () => this.updateRemark(),
      }),
      activeName: "1",
      remark: "",
      // 列表loading状态
      loading: false,
      // 导出loading状态
      importLoading: false,
      importLoading1: false,
      // 核销列表
      recordTable: [],
      // 列表选中
      tempList: [],
      // 列表分页数据
      pageData: { data: [] },
      // 卡列表
      cardList: [],
      // 核销表单
      cancelAterVerificationForm: {},
      // 卡号区间校验判断
      IntervalCheck: "",
      // 列表参数
      params: {
        page_no: 1,
        page_size: 20,
        trade_type: "1",
      },
      // 搜索数据
      advancedForm: {
        app_type_shop_type_flag: "",
        type: "sell_refund_record_sn",
        // 单据状态
        trade_status: "",
        status: "",
        // 款状态
        pay_status: "",
        refund_status: "",
        // 时间
        order_time_range: [],
        // 关键字
        keywords: "",
        cardCodeStart: "",
        cardCodeEnd: "",
      },
      rules: {
        change_price: [
          {
            required: true,
            // validator: validatorAccountNumber,
            trigger: "blur",
          },
        ],
      },
    };
  },
  activated () {
    this.getList();
    this.$store.commit("DEL_VISITED_VIEWS", this.$route);
  },
  mounted () {
    API_order.getShopExtAuth().then(resStatus => {//是否开启双选商城
      if (resStatus) {
        this.double_choice_mall_open =
          resStatus.double_choice_mall_open === "OPEN";
      }
    });
    this.getList()
    this.$store.commit("DEL_VISITED_VIEWS", this.$route);
  },
  // mounted() {
  //   that = this;
  //   // if (sessionStorage.getItem("activeName")) {
  //   //   this.activeName = sessionStorage.getItem("activeName");
  //   // }
  //   // console.log(this.activeName);
  //   // this.params.trade_type = this.activeName;
  //   // 获取数据列表
  //   this.GET_OrderList(this.params);
  // },
  // destroyed() {
  //   // sessionStorage.removeItem("activeName");
  // },
  // activated() {
  // },
  // filters: {
  // },
  methods: {
    checkPrice () {
      let max = 99999;
      if (this.activeName == 1) {
        max = this.cancelAterVerificationForm.total_payable_price - this.cancelAterVerificationForm.curr_payable_price
      }
      if (this.activeName == 2) {
        max = this.cancelAterVerificationForm.after_discount_price - this.cancelAterVerificationForm.curr_refund_price;
      }
      this.cancelAterVerificationForm.change_price = Math.max(0, Math.min(this.cancelAterVerificationForm.change_price, max))
    },
    PermissionToCheck () {
      return API_order.getShopExtAuth().then((res) => {
        if (res.open !== "OPEN") {
          this.$message.error(
            "平台已关闭销售开卡功能，如有其他问题，请联系平台"
          );
          setTimeout(() => {
            this.$store.getters.permission_routers.forEach((item) => {
              if (item.path === "/finance") {
                // 关闭菜单
                item.children.forEach((value, index) => {
                  if (value.path === "cancel-after-verification") {
                    item.children.splice(index, 1);
                    this.$router.push("/finance/enterprise-overview");
                  }
                });
              }
            });
          }, 3000);
        } else {
          return true;
        }
      });
    },
    // 卡号区间校验
    setIntervalCheck () {
      console.log(
        this.advancedForm.cardCodeStart,
        this.advancedForm.cardCodeEnd
      );
      const start = this.splitCard(this.advancedForm.cardCodeStart);
      const end = this.splitCard(this.advancedForm.cardCodeEnd);
      console.log(start, end);
      if (
        (start.CardPrefix === "" && end.CardPrefix === "") ||
        start.CardPrefix === end.CardPrefix
      ) {
        this.IntervalCheck = "";
      } else {
        return (this.IntervalCheck = "2");
      }
      if (Number(start.number) <= Number(end.number)) {
        this.IntervalCheck = "";
      } else {
        return (this.IntervalCheck = "1");
      }
    },
    splitCard (data) {
      const regular = /^[1-9]\d*|0$/;
      let CardPrefix = "";
      let number = 0;
      console.log(CardPrefix);
      if (data === "")
        return {
          CardPrefix: data,
          number,
          index: 0,
        };
      for (let index = 0; index < data.length; index++) {
        if (regular.test(data[index])) {
          console.log(data);
          CardPrefix = data.slice(0, index);
          number = data.slice(index);
          console.log(CardPrefix, number);
          return {
            CardPrefix,
            number,
            index,
          };
        } else if (index === data.length - 1) {
          console.log(data, 0, index);
          return {
            CardPrefix: data,
            number,
            index,
          };
        }
      }
    },
    // 核销金额校验
    changePrice (e) {
      this.cancelAterVerificationForm.change_price = e
        .match(/^\d*(\.?\d{0,2})/g)[0]
        .slice(0, 16);
    },
    // 页签选择
    setType (el) {
      this.activeName = el.name;
      this.pageData.data = [];
      this.params = {
        page_no: 1,
        page_size: 20,
        trade_type: this.activeName,
      };
      this.IntervalCheck = "";
      this.tempList = [];
      this.advancedForm = {
        app_type_shop_type_flag: "",
        // 单据状态
        trade_status: "",
        status: "",
        // 款状态
        pay_status: "",
        refund_status: "",
        // 时间
        order_time_range: [],
        // 关键字
        keywords: "",
        cardCodeStart: "",
        cardCodeEnd: "",
        type: this.activeName === "2" ? "sn" : "sell_refund_record_sn",
      };
      this.getList();
    },
    // 选中数据
    orderListSelectionChange (list) {
      this.tempList = list;
    },
    getList () {
      let params = {
        ...this.params,
        ...this.advancedForm,
      };
      switch (this.advancedForm.type) {
        case "sn":
          params.sn = params.keywords;
          break;
        case "sell_refund_record_sn":
          params.sell_refund_record_sn = params.keywords;
          break;
        case "client_name":
          params.client_name = params.keywords;
          break;
        case "link_name":
          params.link_name = params.keywords;
          break;
        case "sell_name":
          params.sell_name = params.keywords;
          break;
        case "shop_name":
          params.shop_name = params.keywords;
          break;
        default:
          break;
      }
      if (
        this.advancedForm.order_time_range &&
        this.advancedForm.order_time_range.length !== 0
      ) {
        params.start_time =
          this.advancedForm.order_time_range[0].getTime() / 1000;
        params.end_time =
          this.advancedForm.order_time_range[1].getTime() / 1000 + 86400;
      }
      delete params.keywords;
      delete params.type;
      delete params.order_time_range;
      this.GET_OrderList(params);
      this.$nextTick(() => {
        this.$refs[`enTableLayout${this.activeName}`].clearSelected();
      });
    },
    spanMethod (opt) {
      switch (+this.activeName) {
        case 1:
          return spanMethod(opt, [2, 3, 4]);
        case 2:
          return spanMethod(opt, [3, 4, 5]);
        case 3:
          return spanMethod(opt, [2, 3, 4, 5, 6]);
        default:
          break;
      }
    },
    /** 高级搜索事件触发 */
    advancedSearchEvent () {
      const cardStart = this.Trim(this.advancedForm.cardCodeStart);
      const cardEnd = this.Trim(this.advancedForm.cardCodeEnd);
      const start = this.splitCard(cardStart);
      const end = this.splitCard(cardEnd);
      if (
        (cardStart !== "" || cardEnd !== "") &&
        (this.advancedForm.keywords !== "" ||
          this.advancedForm.order_time_range.length !== 0 ||
          this.advancedForm.pay_status !== "" ||
          this.advancedForm.status !== "" ||
          this.advancedForm.trade_status !== "" ||
          this.advancedForm.refund_status !== "" ||
          this.advancedForm.app_type_shop_type_flag !== "")
      )
        return (
          (this.IntervalCheck = "3"),
          this.$message.error("卡号区间搜索不能和其他筛选条件同时使用")
        );
      if ((this.IntervalCheck = "3")) this.IntervalCheck = "";
      if (
        !(
          (cardStart !== "" && cardEnd !== "") ||
          (cardStart === "" && cardEnd === "")
        )
      )
        return (this.IntervalCheck = "5");

      if (
        cardStart.indexOf(" ") !== -1 ||
        cardStart.indexOf(" ") !== -1 ||
        start.CardPrefix.length > 5 ||
        start.number.length < 3 ||
        end.number.length < 3
      )
        return (this.IntervalCheck = "4");
      this.params.page_no = 1;
      let params = {
        ...this.params,
        ...this.advancedForm,
      };
      switch (this.advancedForm.type) {
        case "sn":
          params.sn = params.keywords;
          break;
        case "sell_refund_record_sn":
          params.sell_refund_record_sn = params.keywords;
          break;
        case "client_name":
          params.client_name = params.keywords;
          break;
        case "link_name":
          params.link_name = params.keywords;
          break;
        case "sell_name":
          params.sell_name = params.keywords;
          break;
        case "shop_name":
          params.shop_name = params.keywords;
          break;
        default:
          break;
      }
      console.log(this.advancedForm);
      if (
        this.advancedForm.order_time_range &&
        this.advancedForm.order_time_range.length !== 0
      ) {
        params.start_time =
          this.advancedForm.order_time_range[0].getTime() / 1000;
        params.end_time =
          this.advancedForm.order_time_range[1].getTime() / 1000 + 86400;
      }
      delete params.keywords;
      delete params.type;
      delete params.order_time_range;
      this.GET_OrderList(params);
    },
    remove (arr, str) {
      var index = arr.indexOf(str);
      return arr.splice(index, 1);
    },
    //导出
    submitImport (type) {
      this.PermissionToCheck().then((isRes) => {
        if (!isRes) return;
        const cardStart = this.Trim(this.advancedForm.cardCodeStart);
        const cardEnd = this.Trim(this.advancedForm.cardCodeEnd);
        const start = this.splitCard(cardStart);
        const end = this.splitCard(cardEnd);
        if (
          (cardStart !== "" || cardEnd !== "") &&
          (this.advancedForm.keywords !== "" ||
            this.advancedForm.order_time_range.length !== 0 ||
            this.advancedForm.pay_status !== "" ||
            this.advancedForm.status !== "" ||
            this.advancedForm.trade_status !== "" ||
            this.advancedForm.refund_status !== "" ||
            this.advancedForm.app_type !== "")
        )
          return (
            (this.IntervalCheck = "3"),
            this.$message.error("卡号区间搜索不能和其他筛选条件同时使用")
          );
        if ((this.IntervalCheck = "3")) this.IntervalCheck = "";
        if (
          !(
            (cardStart !== "" && cardEnd !== "") ||
            (cardStart === "" && cardEnd === "")
          )
        )
          return (this.IntervalCheck = "5");

        if (
          cardStart.indexOf(" ") !== -1 ||
          cardStart.indexOf(" ") !== -1 ||
          start.CardPrefix.length > 5 ||
          start.number.length < 3 ||
          end.number.length < 3
        )
          return (this.IntervalCheck = "4");
        this.params.page_no = 1;
        let params = {
          ...this.params,
          ...this.advancedForm,
        };
        switch (this.advancedForm.type) {
          case "sn":
            params.sn = params.keywords;
            break;
          case "sell_refund_record_sn":
            params.sell_refund_record_sn = params.keywords;
            break;
          case "client_name":
            params.client_name = params.keywords;
            break;
          case "link_name":
            params.link_name = params.keywords;
            break;
          case "sell_name":
            params.sell_name = params.keywords;
            break;
          case "shop_name":
            params.shop_name = params.keywords;
            break;
          default:
            break;
        }
        console.log(this.advancedForm);
        if (
          this.advancedForm.order_time_range &&
          this.advancedForm.order_time_range.length !== 0
        ) {
          params.start_time =
            this.advancedForm.order_time_range[0].getTime() / 1000;
          params.end_time =
            this.advancedForm.order_time_range[1].getTime() / 1000 + 86400;
        }
        delete params.keywords;
        delete params.type;
        delete params.order_time_range;
        // let params = { ...this.params };
        if (type == 1) {
          params.ids = this.tempList.map((g) => {
            return g.id;
          });
          if (!params.ids.length)
            return this.$message.error("请先选择要导出的数据");
        }
        if (type == 0) {
          this.importLoading = true;
        } else {
          this.importLoading1 = true;
        }
        let orderFunc = "getCardsUnderWrite";
        let _list = [];
        let _fl_num = [];
        let templateName = "";
        switch (this.activeName) {
          case "1":
            templateName = "销售订单核销列表";
            _fl_num = [1, 2, 3];
            _list = [
              ["销售单据编号", "sell_refund_record_sn"],
              ["所属商城", "shop_name"],
              ["需开卡总数", "card_num"],
              ["已开卡数量", "open_card_num"],
              ["应收金额", "total_payable_price"],
              ["已收金额", "curr_payable_price"],
              ["客户名称", "client_name"],
              ["应用类型", "app_type_shop_type_flag"],
              ["联系人名称", "link_name"],
              ["销售人员", "sell_name"],
              ["付款状态", "pay_status"],
              ["单据状态", "trade_status"],
              ["单据生成日期", "create_time"],
              ["收款备注", "payable_remark"],
            ];
            break;
          case "2":
            templateName = "销售退单核销列表";
            _fl_num = [2, 3, 4];
            _list = [
              ["销售退单编号", "sn"],
              ["关联销售单号", "sell_refund_record_sn"],
              ["所属商城", "shop_name"],
              ["需退卡总数", "refund_num"],
              ["已退卡数量", "refund_card_num"],
              ["应退金额", "after_discount_price"],
              ["已退金额", "refunded_price"],
              ["客户名称", "client_name"],
              ["应用类型", "app_type_shop_type_flag"],
              ["联系人名称", "link_name"],
              ["销售人员", "sell_name"],
              ["退款状态", "refund_status"],
              ["单据状态", "status"],
              ["单据生成日期", "create_time"],
              ["退款备注", "refund_remark"],
            ];
            break;
          case "3":
            templateName = "来往对账核销列表";
            _fl_num = [1, 2, 3, 4, 5];
            _list = [
              ["销售单据编号", "sell_refund_record_sn"],
              ["所属商城", "shop_name"],
              ["需开卡总数", "card_num"],
              ["已开卡总数", "open_card_num"],
              ["需退卡总数", "refund_num"],
              ["已退卡总数", "curr_refund_num"],
              ["应收金额", "total_payable_price"],
              ["已收金额", "curr_payable_price"],
              ["应退金额", "total_refund_price"],
              ["已退金额", "curr_refund_price"],
              ["余额", "balance"],
              ["客户名称", "client_name"],
              ["应用类型", "app_type_shop_type_flag"],
              ["联系人名称", "link_name"],
              ["销售人员", "sell_name"],
              ["单据生成日期", "create_time"],
              ["备注信息", "remark"],
            ];
            break;
          default:
            break;
        }
        delete params.page_no;
        delete params.page_size;
        let tHeaders = _list.map((item) => item[0]);
        let filterVals = _list.map((item) => item[1]);

        let merges = [];
        let wscols = new Array(tHeaders.length).fill(20).map((n) => {
          return {
            wch: n,
          };
        });
        API_order[orderFunc](params)
          .then((res) => {
            let listkey = this.activeName == 2 ? "shop_refund_records" : "shop_account_sell_list"
            let list = changeDataList(res.data, listkey, [
              "shop_name",
              "card_num",
              "open_card_num",
              "refund_num",
              "refund_card_num",
              "curr_refund_num",
              "need_refund_price"
            ]);
            let data = list.map((item, index) => {
              if (item.span > 1) {
                let arr = new Array(tHeaders.length)
                  .fill(0)
                  .map((a, i) => {
                    if (_fl_num.includes(i)) return null;
                    return {
                      s: { r: index + 1, c: i },
                      e: { r: index + item.span, c: i },
                    };
                  })
                  .filter((a) => a);
                merges.push(...arr);
              }

              item.create_time = Foundation.unixToDate(
                item.create_time,
                "yyyy-MM-dd hh:mm:ss"
              );
              let ps1 = item.total_payable_price < item.curr_payable_price ? "(超付)" : ""
              let rs1 = item.after_discount_price < item.refunded_price ? "(超退)" : ""

              item.pay_status = this.getPayTypeName(item.pay_status) + ps1;
              item.refund_status = this.getRefundTypeName(item.refund_status) + rs1;
              item.trade_status = this.getTradeTypeName(item.trade_status);
              item.status = this.getTradeTypeName(item.status);
              item.app_type_shop_type_flag = this.getAppTypeName(item.app_type_shop_type_flag);
              item.curr_refund_num = item.curr_refund_num || 0;
              item.refund_card_num = item.refund_card_num || item.curr_refund_num || 0;
              item.refund_num = item.refund_num || item.card_num || 0;
              let obj = {};
              tHeaders.forEach((key, i) => (obj[key] = item[filterVals[i]]));
              return obj;
            });

            downloadExcel(
              {
                data,
                merges,
                wscols,
              },
              templateName
            );
            // handleDownload(list, tHeaders, filterVals, templateName, true);
            this.importLoading = false;
            this.importLoading1 = false;
          })
          .catch((res) => {
            this.importLoading = false;
            this.importLoading1 = false;
          });
      });
    },
    Trim (str) {
      return str.replace(/(^\s*)|(\s*$)/g, "");
    },
    GET_OrderList (params) {
      this.PermissionToCheck().then((isRes) => {
        if (!isRes) return;
        // this.loading = true;
        API_order.getCardsUnderWrite(params).then((res) => {
          this.pageData = res;

          let listkey = this.activeName == 2 ? "shop_refund_records" : "shop_account_sell_list"
          this.pageData.data = changeDataList(
            res.data,
            listkey,
            [
              "shop_name",
              "card_num",
              "open_card_num",
              "refund_num",
              "refund_card_num",
              "curr_refund_num",
              "need_refund_price"
            ]
          );
          console.log(this.pageData);
          // this.loading = false;
        });
      });
    },
    // 查看
    getInfoUnderWrite (row) {
      this.$store.dispatch("addVisitedViews", this.$route);
      this.PermissionToCheck().then((isRes) => {
        if (!isRes) return;
        const { id } = row;
        if (this.activeName == 1) {
          this.$router.push({
            name: "verificationOrderDetail",
            query: {
              id,
            },
          });
        }
        if (this.activeName == 2) {
          this.$router.push({
            name: "verificationRefundDetail",
            query: {
              id,
            },
          });
        }
        // let params = {
        //   trade_type: this.params.trade_type,
        // };
        // if (this.params.trade_type === "2") {
        //   params.sn = row.sn;
        // } else {
        //   params.sell_refund_record_sn = row.sell_refund_record_sn;
        // }
        // this.$router.push({
        //   path: `/finance/cancel-after-verification-detailed`,
        //   query: params,
        // });
      });
      return;
    },
    // 核销记录
    getRemarkUnderWrite (row) {
      let params = {
        trade_type: this.params.trade_type,
      };
      if (this.params.trade_type === "2") {
        params.sn = row.sn;
        API_order.getRefundRemarkUnderWrite(params).then((res) => {
          this.recordTable = res;
          this.recordDialog.display();
        });
      } else {
        params.sell_refund_record_sn = row.sell_refund_record_sn;
        API_order.getRemarkUnderWrite(params).then((res) => {
          this.recordTable = res;
          this.recordDialog.display();
        });
      }
    },
    // 核销回显
    cancelAterVerification (row) {
      console.log(row);
      let params = {};
      this.cancelAterVerificationForm = {
        ...row,
        change_price: "",
        payable_remark: "",
        refund_remark: "",
      };
      if (this.activeName === "1") {
        params.sell_refund_record_sn = row.sell_refund_record_sn;
        API_order.getTradeIds(params).then((res) => {
          this.cancelAterVerificationForm.ids = res.map((g) => {
            return g.id;
          });
        });
      } else if (this.activeName === "2") {
        params.sn = row.sn;
        API_order.getRefundIds(params).then((res) => {
          this.cancelAterVerificationForm.ids = res.map((g) => {
            return g.id;
          });
        });
      }
      this.operDialog.display();
    },
    cardSpanMethod ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 0) {
        return {
          rowspan: row.span[0],
          colspan: 1,
        };
      }
      if (columnIndex == 1) {
        return {
          rowspan: row.span[1],
          colspan: 1,
        };
      }
    },
    // 开卡退卡回显
    getCloseCardsTypeChange (row) {
      console.log(row);
      let params = {
        trade_type: this.params.trade_type,
      };
      if (this.params.trade_type === "2") {
        params.sn = row.sn;
        API_order.getCloseCardsTypeChange(params).then((res) => {
          // 处理cardList成多行
          this.cardList = createShopCardList(res);
          params.ids = res.map((g) => {
            return g.id;
          });
          this.operCardDialog.display({
            title: "退卡操作",
            beforeConfirm: () => this.closeAllCards(params),
          });
        });
      } else {
        params.sell_refund_record_sn = row.sell_refund_record_sn;
        API_order.getOpenCardsTypeChange(params).then((res) => {
          // 处理cardList成多行
          let _cardList = createShopCardList(res);
          this.cardList = _cardList;
          params.ids = res.map((g) => {
            return g.id;
          });
          this.operCardDialog.display({
            title: "开卡操作",
            beforeConfirm: () => this.openAllCards(params),
          });
        });
      }
    },
    // 核销确定
    editCurrPrice (formName) {
      this.PermissionToCheck().then((isRes) => {
        if (!isRes) return;
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let params = {
              client_name: this.cancelAterVerificationForm.client_name,
              change_price: this.cancelAterVerificationForm.change_price,
              trade_type: this.params.trade_type,
              remark: this.cancelAterVerificationForm.remark,
            };
            if (this.params.trade_type === "2") {
              params.sn = this.cancelAterVerificationForm.sn;
            } else {
              params.sell_refund_record_sn =
                this.cancelAterVerificationForm.sell_refund_record_sn;
            }
            API_order.editCurrPrice(params).then((res) => {
              if (res.code !== 200) return this.$message.error(res.massage);
              this.$message.success(res.massage);
              this.GET_OrderList(this.params);
            });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      });
    },
    // 开卡
    openAllCards (params) {
      this.$confirm(`确定要对该卡号段进行开卡（激活）操作吗？`, "提示", {
        type: "warning",
      }).then(() => {
        this.PermissionToCheck().then((isRes) => {
          API_order.openAllCards(params).then((res) => {
            if (res.code !== 200) return this.$message.error(res.massage);
            this.$message.success(res.massage);
            this.GET_OrderList(this.params);
          });
        });
      });
    },
    // 退卡
    closeAllCards (params) {
      this.$confirm(`确定要对该卡号段进行退卡（禁用）操作吗？`, "提示", {
        type: "warning",
      }).then(() => {
        this.PermissionToCheck().then((isRes) => {
          if (!isRes) return;
          API_order.closeAllCards(params).then((res) => {
            if (res.code !== 200) return this.$message.error(res.massage);
            this.$message.success(res.massage);
            this.GET_OrderList(this.params);
          });
        });
      });
    },
    // 备注
    updateRemark (row) {
      this.PermissionToCheck().then((isRes) => {
        if (!isRes) return;
        let params = {
          sell_refund_record_sn:
            this.cancelAterVerificationForm.sell_refund_record_sn,
          remark: this.remark,
        };
        API_order.updateRemark(params).then((res) => {
          if (res.code !== 200) return this.$message.error(res.massage);
          this.$message.success(res.massage);
          this.GET_OrderList(this.params);
          this.remark = "";
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.writeOffOpenCard {
  background: #fff;

  .el-tab-pane {
    height: calc(100vh - 140px);
    min-height: 640px;
  }
}

/deep/ .el-scrollbar__wrap {
  margin-bottom: 10px !important;
}

/deep/ .el-table__fixed-body-wrapper {
  top: 34.5px !important;
}

/deep/ .en-table-layout .en-table-header {
  padding: 0;
}

/deep/ .el-tabs__header {
  margin: 0;
}

.cardInterval {
  position: absolute;
  top: 26px;
  color: rgb(251, 65, 65);
  width: 400px;
  font-size: 10px;
}

p {
  margin-bottom: 5px;
}

.refund-no-num {
  color: #999;
  font-size: 12px;
  margin-bottom: 0;
}

.c-a-form {
  .el-form-item {
    margin-bottom: 8px;
  }
}

.col-auto {
  /deep/ .el-form-item__error {
    z-index: 8;
  }
}
</style>
